const localeMessages = {
  en_US: {
    test: "test",
    "dashboard.player": "Player",
    "dashboard.amount": "Amount",
    "dashboard.pay": "Pay",
    "dashboard.log": "Log",
    "button.leave": "Leave the table",
    "button.reload": "Reload",
    "button.allLog": "Show all log",
    "button.joinTable": "Join table",
    "button.watch": "Watch",
    "placeHolder.enterName": "Enter your name",
    "modalTitle.incomingLog": "Incoming log of",
    "modalTitle.outComingLog": "Outcoming log of",
    "modalTitle.outGoingLog": "OutGoing log",
    "message.transferred": "transferred",
    "message.received": "received",
    "message.from": "from",
    "message.confirm": "R u sure?",
    "title.login": "Log in",
    "title.logout": "Log out",
    "title.welcome": "Welcome",
    "message.nowYouCan": "Now you can",
    "button.create": "Create new table",
    "message.or": "OR",
    "placeHolder.enterTableId": "Enter table ID",
    "message.transferTo": "Transfer to",
    "placeHolder.lossAmount": "Enter loss amount",
    "button.backHome": "Back home",
    "button.scanQrCode": "Scan QR code",
  },

  vi_VN: {
    test: "Thử nghiệm",
    "dashboard.player": "Người chơi",
    "dashboard.amount": "Tài khoản",
    "dashboard.pay": "Thanh toán",
    "dashboard.log": "Lịch sử",
    "button.leave": "Rời bàn",
    "button.reload": "Tải lại",
    "button.allLog": "Lịch sử",
    "button.joinTable": "Tham gia",
    "button.watch": "Xem",
    "placeHolder.enterName": "Nhập tên",
    "modalTitle.incomingLog": "Lịch sử nhận",
    "modalTitle.outComingLog": "Lịch sử chuyển",
    "modalTitle.outGoingLog": "Lịch sử chuyển",
    "message.transferred": "đã chuyển cho",
    "message.received": "đã nhận",
    "message.from": "từ",
    "message.confirm": "Bạn chắc chứ?",
    "title.login": "Đăng nhập",
    "title.logout": "Đăng xuất",
    "title.welcome": "Xin chào",
    "message.nowYouCan": "Bây giờ bạn có thể",
    "button.create": "Tạo bàn mới",
    "message.or": "Hoặc",
    "placeHolder.enterTableId": "Nhập ID bàn",
    "message.transferTo": "Chuyển cho",
    "placeHolder.lossAmount": "Nhập số tiền chuẩn bị mất",
    "button.backHome": "Về trang chủ",
    "button.scanQrCode": "Quét mã QR",
  },
};
export default localeMessages;
